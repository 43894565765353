<template>
  <div class="card compact shadow-lg font-bold" v-if="statictics.length > 0">
    <div class="card-body flex items-start justify-start">
      <div class="flex flex-wrap space-x-1 space-y-1">
        <div
          class="card font-normal"
          :style="isMobile ? { width: '100%' } : {}"
          v-for="(
            { label, value, rate, mid, diff, reverse }, index
          ) in statictics"
          :key="index"
        >
          <div :class="['card-body', $$colorLevel(mid, diff, rate, reverse)]">
            <div class="card-title">{{ label }}</div>
            <div class="w-full flex-end space-x-4" :style="{ height: '3rem' }">
              <div class="text-2xl">{{ value | formatNumber }}</div>
              <div
                class="bg-white p-1 text-sm rounded-md"
                :class="rate < 1 ? 'text-red-500' : 'text-green-500'"
              >
                ({{ (rate - 1) | percentage }})
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'long-short',

  data: () => ({
    isMobile: global.IS_MOBILE,
  }),

  computed: {
    ...mapGetters({
      statictics: 'market/statictics',
    }),
  },
}
</script>
