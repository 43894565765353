<template>
  <div class="card compact shadow-lg font-bold" v-if="activeOI.btc">
    <div class="card-body flex items-start justify-start">
      <div
        class="md:flex sm:space-x-1 space-y-1"
        :style="{ width: '26rem', maxWidth: '90vw' }"
      >
        <div class="card font-normal w-full md:w-52">
          <div
            class="card-body"
            :class="$$colorLevel(0, 0.2, activeOI.btc.rate, true)"
          >
            <div class="card-title text-xs">BTC</div>
            <div class="w-full flex-between text-xs">
              <span>OI (USD)</span>
              <span>
                {{ activeOI.btc.oi | round | formatNumber }}
              </span>
            </div>
            <div class="w-full flex-between text-xs">
              <span>OI (BTC)</span>
              <span>
                {{ (activeOI.btc.oi / btcusd) | round | formatNumber }}
              </span>
            </div>
            <div class="w-full flex-between text-xs">
              <span>Active OI (USD)</span>
              <span>{{ activeOI.btc.activeOI | round | formatNumber }}</span>
            </div>
            <div class="w-full flex-between text-xs">
              <span>Active OI (BTC)</span>
              <span>
                {{ (activeOI.btc.activeOI / btcusd) | round | formatNumber }}
              </span>
            </div>
            <div class="w-full flex-between text-xs">
              <span>Change (BTC)</span>
              <span>
                {{ (activeOI.btc.change / btcusd) | round | formatNumber }}
              </span>
            </div>
            <div class="w-full flex-between text-xs">
              <span>Change (USD)</span>
              <span>{{ activeOI.btc.change | round | formatNumber }}</span>
            </div>
            <div class="w-full flex-between text-xs">
              <span>Basic</span>
              <span>{{ activeOI.btc.basic | percentage }}</span>
            </div>
          </div>
        </div>

        <div class="card font-normal w-full md:w-52">
          <div
            class="card-body"
            :class="$$colorLevel(0, 0.2, activeOI.eth.rate, true)"
          >
            <div class="card-title text-xs">ETH</div>
            <div class="w-full flex-between text-xs">
              <span>OI (USD)</span>
              <span>{{ activeOI.eth.oi | round | formatNumber }}</span>
            </div>
            <div class="w-full flex-between text-xs">
              <span>OI (eth)</span>
              <span>
                {{ (activeOI.eth.oi / ethusd) | round | formatNumber }}
              </span>
            </div>
            <div class="w-full flex-between text-xs">
              <span>Active OI (USD)</span>
              <span>
                {{ activeOI.eth.activeOI | round | formatNumber }}
              </span>
            </div>
            <div class="w-full flex-between text-xs">
              <span>Active OI (eth)</span>
              <span>
                {{ (activeOI.eth.activeOI / ethusd) | round | formatNumber }}
              </span>
            </div>
            <div class="w-full flex-between text-xs">
              <span>Change (eth)</span>
              <span>
                {{ (activeOI.eth.change / ethusd) | round | formatNumber }}
              </span>
            </div>
            <div class="w-full flex-between text-xs">
              <span>Change (USD)</span>
              <span>{{ activeOI.eth.change | round | formatNumber }}</span>
            </div>
            <div class="w-full flex-between text-xs">
              <span>Basic</span>
              <span>{{ activeOI.eth.basic | percentage }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'active-oi',
  computed: {
    ...mapState({
      oi: (state) => state.market.oi,
    }),
    ...mapGetters({
      btcusd: 'market/btcusd',
      ethusd: 'market/ethusd',
    }),
    activeOI() {
      const res = {}
      const { basic, activeOI, OI } = this.oi || {}
      if (activeOI?.now?.btc) {
        const now = activeOI.now.btc
        const prev = activeOI.prev.btc
        const change = now - prev
        res.btc = {
          oi: OI.btc,
          activeOI: Math.abs(now),
          change,
          rate: change / OI.btc,
          basic: basic.btc,
        }
      }
      if (activeOI?.now?.eth) {
        const now = activeOI.now.eth
        const prev = activeOI.prev.eth
        const change = now - prev
        res.eth = {
          oi: OI.eth,
          activeOI: Math.abs(now),
          change,
          rate: change / OI.eth,
          basic: basic.eth,
        }
      }
      return res
    },
  },
}
</script>
