<template>
  <div class="card compact shadow-lg font-bold">
    <div class="card-body">
      <div :class="isMobile ? 'space-y-1' : 'flex-start space-x-1'">
        <div class="card border">
          <div :class="['card-body', $$colorLevel(22.5, 56, usdt)]">
            <h2 class="card-title">Coinbase/USDT</h2>
            <div class="text-right">{{ usdt | formatNumber }}</div>
          </div>
        </div>
        <div class="card border">
          <div :class="['card-body', $$colorLevel(22.5, 56, bybit)]">
            <h2 class="card-title">Coinbase/Bybit</h2>
            <div class="text-right">{{ bybit | formatNumber }}</div>
          </div>
        </div>
        <div class="card font-normal border">
          <div
            :class="[
              'card-body w-full md:w-40',
              $$colorLevel(0, 300, divergence, true),
            ]"
          >
            <div class="w-full flex-between text-xs">
              <span>Price </span>
              <span>{{ bybitPrices.last | formatNumber }}</span>
            </div>
            <div class="w-full flex-between text-xs">
              <span>Mark price</span>
              <span>
                {{ bybitPrices.mark | formatNumber }}
              </span>
            </div>
            <div class="w-full flex-between text-xs">
              <span>Divergence</span>
              <span>
                {{ divergence | formatNumber }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const LAST_IDX = 2
const MARK_IDX = 5
const INDEX_IDX = 6

export default {
  name: 'btc-price',

  data: () => ({
    isMobile: global.IS_MOBILE,
  }),

  computed: {
    ...mapGetters({
      misc: 'market/misc',
    }),
    bybitPrices() {
      const prices = this.misc.prices?.bybit?.BTCUSD || []
      const last = prices[LAST_IDX] || 0
      const mark = prices[MARK_IDX] || 0
      return { last, mark, divergence: last - mark }
    },
    coinbaseIndexes() {
      // special logic: binance btcusdt ---> coinbase-pro index
      const index = this.misc.prices?.binance?.BTCUSDT?.[INDEX_IDX] || 0
      const last =
        this.misc.prices?.['coinbase-pro']?.['BTC-USD']?.[LAST_IDX] || 0
      const bybitLast = this.misc?.prices?.bybit?.BTCUSD?.[LAST_IDX] || 0
      return { usdt: last - index, bybit: last - bybitLast }
    },
    usdt() {
      return this.coinbaseIndexes.usdt
    },
    bybit() {
      return this.coinbaseIndexes.bybit
    },
    divergence() {
      return this.bybitPrices.divergence
    },
  },
}
</script>
