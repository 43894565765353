<template>
  <div class="card compact shadow-lg font-bold" v-if="longshort?.length">
    <div class="card-body flex items-start justify-start">
      <div class="flex flex-wrap space-x-1 space-y-1">
        <div
          class="card w-full font-normal"
          v-for="({ label, day, account }, index) in longshort"
          :key="index"
        >
          <div :class="['card-body m-0', $$colorLevel(0.5, 0.5, day, true)]">
            <div class="card-title text-xs">{{ label }}</div>
            <div class="w-full flex-between text-xs">
              <span>Day</span>
              <span>{{ day | percentage }}</span>
            </div>
            <div class="w-full flex-between text-xs">
              <span>Account</span>
              <span>{{ account | percentage }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'long-short',
  computed: {
    ...mapState({
      longshort: (state) => state.market.longshort,
    }),
  },
}
</script>
