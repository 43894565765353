<template>
  <div>
    <kbds class="mb-1" />
    <div class="space-y-1" v-if="isMobile">
      <tether-market-cap class="gray-card" />
      <div class="flex-between space-x-1" :style="{ height: '25dvh' }">
        <week-gap
          class="gray-card"
          :style="{ width: '49vw', height: '25dvh' }"
        />
        <yahoo-stocks
          class="h-full gray-card"
          :style="{ width: '49vw', height: '25dvh' }"
        />
      </div>
      <btc-price class="gray-card w-full" />
      <funding-rate-card class="gray-card" />
      <active-oi class="gray-card w-full" />
      <long-short class="gray-card" />
      <statistics-data class="gray-card" />
    </div>
    <div v-else>
      <div>
        <div class="flex items-start" :style="{ height: '16dvh' }">
          <btc-price
            class="gray-card"
            :style="{ width: '30rem', height: '16dvh' }"
          />
          <week-gap
            class="gray-card"
            :style="{ width: '12rem', height: '16dvh' }"
          />
          <tether-market-cap
            class="gray-card"
            :style="{ width: '12rem', height: '16dvh' }"
          />
          <yahoo-stocks
            class="gray-card"
            :style="{ width: '12rem', height: '16dvh' }"
          />
        </div>
        <div
          class="flex items-start justify-start mt-1"
          :style="{ height: '55dvh' }"
        >
          <long-short class="gray-card" :style="{ width: '20vw' }" />
          <div class="flex flex-col h-full">
            <funding-rate-card class="gray-card" />
            <active-oi class="mt-4 gray-card" :style="{ height: '23dvh' }" />
          </div>
        </div>
      </div>
      <statistics-data class="gray-card" v-if="true" />
    </div>
  </div>
</template>

<script>
import Kbds from '@/components/apps/dashboard/kbds/index.vue'
import WeekGap from '@/components/apps/dashboard/week-gap/index.vue'
import TetherMarketCap from '@/components/apps/dashboard/tether-market-cap/index.vue'
import ActiveOi from '@/components/apps/dashboard/active-oi/index.vue'
import BtcPrice from '@/components/apps/dashboard/btc-price/index.vue'
import LongShort from '@/components/apps/dashboard/long-short/index.vue'
import YahooStocks from '@/components/apps/dashboard/yahoo-stocks/index.vue'
import StatisticsData from '@/components/apps/dashboard/statistics-data/index.vue'
import FundingRateCard from '@/components/apps/dashboard/funding-rate-card/index.vue'

export default {
  name: 'dashboard',

  components: {
    Kbds,
    TetherMarketCap,
    WeekGap,
    BtcPrice,
    ActiveOi,
    LongShort,
    YahooStocks,
    StatisticsData,
    FundingRateCard,
  },

  meta: {
    title: 'Dashboard',
  },

  data: () => ({
    isMobile: global.IS_MOBILE,
    links: [
      ['Stats', '/stats'],
      ['IVBand', '/iv-band'],
      ['X-Chart', '/x-chart'],
      ['Collections', '/collections'],
      ['Market Points', '/market-points'],
      ['Binance Stats', '/binance-stats'],
      ['GMX Board', '/gmx-board'],
    ],
  }),

  mounted() {
    setTimeout(() => {
      localStorage.market = JSON.stringify(this.$store.state.market)
    }, 5000)
  },

  beforeDestroy() {
    this.timer && clearInterval(this.timer)
  },
}
</script>

<style lang="scss" scoped>
.mobile {
  width: 600px;
}
.gray-card {
  @apply card bg-gray-100 border mr-3;
}
</style>
