<template>
  <div
    class="card compact shadow-lg font-bold w-full md:w-64"
    @click.stop="toCoingecko"
  >
    <div class="card-body flex items-start justify-start w-full">
      <h2 class="card-title">MarketCap</h2>
      <div class="space-y-2 w-full">
        <div class="flex-between w-full cursor-pointer">
          <div>USDT</div>
          <div class="text-gray-500 font-semibold text-xs">
            {{ marketCap }}
            <br />
            {{ change }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'tether-market-cap',

  computed: {
    ...mapState({
      coingeckoTether: (state) => state.market?.misc?.coingeckoTether,
    }),
    change() {
      const value = this.coingeckoTether?.change || 0
      if (value === 0) {
        return '--'
      }
      return `(${_round(value, 100)}%)`
    },
    marketCap() {
      const value = this.coingeckoTether?.marketCap || 0
      if (!value) {
        return '--'
      }
      if (value > 1e9) {
        return `$${_round(value / 1e9, 1e3).toLocaleString()}B`
      }
      if (value > 1e6) {
        return `$${_round(value / 1e6, 1e3).toLocaleString()}M`
      }
      return value
    },
  },

  methods: {
    toCoingecko() {
      window.open('https://www.coingecko.com/en/coins/tether')
    },
  },
}

function _round(value, k = 1e3) {
  return Math.round(value * k) / k
}
</script>
