<template>
  <div
    class="card compact shadow-lg font-bold w-full md:w-64"
    v-if="stocks.length > 0"
  >
    <div class="card-body flex items-start justify-start w-full">
      <h2 class="card-title">STOCKS</h2>
      <div class="space-y-2 w-full">
        <div
          v-for="stock in stocks"
          :key="stock.symbol"
          class="flex-between w-full cursor-pointer"
          @click.stop="toYahoo(stock.symbol)"
        >
          <div>{{ stock.symbol || '-' }}</div>
          <div class="text-gray-500 font-semibold text-xs">
            {{ stock.price | formatCurrency }}
            <br v-if="isMobile" />
            ({{ stock.rate | percentage }})
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'yahoo-stocks',

  data: () => ({
    isMobile: global.IS_MOBILE,
  }),
  computed: {
    ...mapGetters({
      misc: 'market/misc',
    }),
    stocks() {
      return (this.misc?.yahoo?.data || []).map((stock) => ({
        symbol: stock.symbol,
        price: stock.now,
        rate: (stock.now - stock.prev) / stock.prev,
      }))
    },
  },
  methods: {
    toYahoo(code) {
      return window.open(`https://finance.yahoo.com/quote/${code}`)
    },
  },
}
</script>
