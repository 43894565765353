<template>
  <div class="card compact shadow-lg font-bold" v-if="rates.length > 0">
    <div class="card-body flex items-start justify-start">
      <div
        :class="[
          'space-x-1 space-y-1',
          isMobile
            ? 'grid grid-cols-2 gap-4'
            : 'flex items-start justify-start',
        ]"
      >
        <div
          class="card bordered bg-gray-100 w-40"
          v-for="({ exchange, rate, preRate }, index) in rates"
          :key="index"
        >
          <div class="card-body">
            <div class="space-y-1 text-xs">
              <div class="uppercase">{{ exchange }}</div>
              <div :class="['rounded-md', classes(rate.value)]">
                <div class="flex-between p-1.5 rounded-md">
                  <div>Rate</div>
                  <div class="text-right">
                    {{ rate.value | ratePercentage }}
                    <br />
                    ({{ rate.timestamp | rateTime }})
                  </div>
                </div>
              </div>
              <div
                :class="['rounded-md', classes(preRate.value)]"
                v-if="preRate.value"
              >
                <div class="flex-between p-1.5 rounded-md">
                  <div>Pre-Rate</div>
                  <div class="text-right">
                    {{ preRate.value | ratePercentage }}
                    <br />
                    ({{ preRate.timestamp | rateTime }})
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const base = new Date('2021/04/23 00:00:00 GMT+0').getTime()
const span = 3600e3 * 8
const RATE_IDX = 8
const PRE_RATE_IDX = 9

const diffs = {
  BYBIT: 0,
  BITMEX: 4 * 3600e3,
  BINANCE: 0,
}
const FR_EXCHANGES = Object.keys(diffs)

export default {
  name: 'funding-rate-card',
  filters: {
    rateTime(timestamp) {
      const hour = new Date(timestamp).getHours()
      return hour > 9 ? `${hour}:00` : `0${hour}:00`
    },
    ratePercentage(val) {
      return val ? Math.round(val * 1e6) / 1e4 + '%' : '--'
    },
  },

  data: () => ({
    isMobile: global.IS_MOBILE,
  }),

  computed: {
    ...mapGetters({
      misc: 'market/misc',
    }),
    rates() {
      const now = Date.now()
      return FR_EXCHANGES.map((EXCHANGE) => {
        const exchange = EXCHANGE.toLowerCase()
        const data = this?.misc?.prices?.[exchange] || {}
        return Object.entries(data)
          .filter(([symbol, [exchange]]) => {
            if (EXCHANGE in diffs) {
              if (exchange === 'bybit') {
                return symbol === 'BTCUSD'
              }
              if (exchange === 'bitmex') {
                return symbol === 'XBTUSD'
              }
              return symbol.includes('BTC')
            }
            return false
          })
          .map(([symbol, data]) => {
            const mark = base + diffs[EXCHANGE]
            const timestamp = Math.ceil((now - mark) / span) * span + mark
            return {
              symbol,
              exchange,
              rate: {
                value: _round(data[RATE_IDX]),
                timestamp,
              },
              preRate: {
                value: _round(data[PRE_RATE_IDX]),
                timestamp: timestamp + span,
              },
            }
          })
      }).flat()
    },
  },

  methods: {
    classes(val) {
      return val ? this.$$colorLevel(-0.0001, 0.0009, -val) : ''
    },
  },
}

function _round(value) {
  return value ? parseFloat(value.toFixed(6)) : 0
}
</script>
