<template>
  <div class="overflow-scroll">
    <div
      class="flex-start space-x-1 w-full sm:pb-0 pb-2"
      :style="{ width: `${width}rem` }"
    >
      <kbd
        class="kbd sm:cursor-pointer"
        v-for="[label, link] in links"
        :key="label"
        @click="$router.push(link)"
        v-text="label"
      />
    </div>
  </div>
</template>

<script>
const links = [
  ['Stats', '/stats'],
  ['IVBand', '/iv-band'],
  ['X-Chart', '/x-chart'],
  ['Collections', '/collections'],
  ['Market Points', '/market-points'],
  ['Binance Stats', '/binance-stats'],
  ['GMX Board', '/gmx-board'],
]

const width = 0.7 * links.reduce((acc, el) => acc + el[0].length + 1, 0)

export default {
  name: 'dashboard-kbds',

  data: () => ({
    links,
    width,
  }),

  computed: {
    wrapperClasses() {
      return ['kbd sm:cursor-pointer mb-0']
    },
  },
}
</script>
