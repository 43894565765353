<template>
  <div class="card compact border shadow-lg font-bold" v-if="weekGaps?.sat">
    <div class="card-body sm:w-48">
      <div :class="['w-full text-xs', !weekGaps?.sat?.reach && 'red-price']">
        <div class="w-full flex-between text-xs">
          <span>SAT</span>
          <span>{{ satPrice | formatCurrency }}</span>
        </div>
        <div class="w-full text-right text-xs">
          {{ satTime }}
        </div>
      </div>

      <div class="w-full text-sm mt-1">
        <div class="w-full flex-between text-xs">
          <span>MON</span>
          <span>{{ monPrice | formatCurrency }}</span>
        </div>
        <div class="w-full text-right text-xs">
          {{ monTime }}
        </div>
      </div>

      <div
        class="w-full flex-between text-xs my-1"
        :class="weekGaps?.standard?.reach ? '' : 'red-price'"
      >
        <span>Standard</span>
        <span>{{ standardPrice | formatCurrency }}</span>
      </div>

      <div
        class="w-full flex-between text-xs mt-1 mb-2"
        :class="weekGaps?.standard?.reach ? '' : 'red-price'"
      >
        <span>Ratio</span>
        <span
          >{{
            Math.round(((monPrice - satPrice) / satPrice) * 10000) / 100
          }}%</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { dateStrings } from '@/utils/string/index.js'

function date(timestamp) {
  const { month, day, hour, min } = dateStrings(timestamp)
  return `(${month}/${day} ${hour}:${min})`
}

export default {
  name: 'week-gap',

  methods: {
    date,
  },

  computed: {
    ...mapState({
      weekGaps: (state) => state.market.weekGap,
    }),
    satPrice() {
      return this.weekGaps?.sat?.price
    },
    monPrice() {
      return this.weekGaps?.mon?.price
    },
    standardPrice() {
      return this.weekGaps?.standard?.price
    },
    satTime() {
      return date(this.weekGaps?.sat?.timestamp)
    },
    monTime() {
      return date(this.weekGaps?.mon?.timestamp)
    },
  },
}
</script>

<style lang="scss" scoped>
.red-price {
  color: rgb(230, 18, 50);
}
</style>
